import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Instagram: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} width="21" height="21" viewBox="0 0 21 21">
            <g fill={color}>
                <path d="M10.325 3.817v.033c-1.005 0-2.01-.009-3.014.004-.63.008-1.254.07-1.85.301-.689.268-1.174.734-1.423 1.44-.187.53-.227 1.082-.242 1.631a152.704 152.704 0 00-.06 3.828c-.006 1.1.01 2.201.16 3.293.08.569.296 1.08.721 1.485.507.482 1.138.683 1.81.754a15.44 15.44 0 001.624.083c1.843.001 3.686-.011 5.528-.025a4.65 4.65 0 001.527-.261c.725-.26 1.24-.733 1.498-1.474.184-.525.227-1.072.24-1.617.033-1.533.057-3.066.052-4.6-.003-.922-.01-1.85-.198-2.759-.167-.801-.623-1.385-1.378-1.72-.54-.24-1.113-.33-1.695-.345-1.1-.027-2.2-.035-3.3-.05m-.002 14.822c-1.219-.018-2.44-.008-3.657-.063-1.157-.052-2.204-.438-3.071-1.237-.592-.545-.98-1.217-1.217-1.981-.234-.754-.274-1.535-.31-2.314a55.935 55.935 0 01.004-5.6c.033-.64.079-1.288.214-1.912.308-1.424 1.152-2.436 2.508-3 .612-.255 1.254-.374 1.912-.428 1.965-.165 3.934-.09 5.899-.08.627.003 1.257.06 1.879.146 1.184.162 2.232.617 3.016 1.562.45.54.746 1.163.866 1.852.096.552.166 1.112.195 1.671a58.246 58.246 0 010 6.07c-.03.588-.08 1.183-.207 1.756-.323 1.448-1.167 2.494-2.547 3.081-.765.326-1.57.442-2.397.44h-3.087v.037" />
                <path d="M8.236 10.643a2.412 2.412 0 002.412 2.4 2.405 2.405 0 002.386-2.408 2.39 2.39 0 00-2.408-2.394 2.398 2.398 0 00-2.39 2.402m-1.756-.409c-.013-1.368 1.142-2.962 2.838-3.537a4.132 4.132 0 014.403 1.165c.939 1.056 1.276 2.292.969 3.661-.348 1.55-1.316 2.596-2.848 3.089-1.567.504-3.32-.031-4.369-1.28-.657-.782-.99-1.68-.993-3.098m7.04-4.07a.953.953 0 01.96-.964c.525 0 .957.43.96.956a.964.964 0 01-.966.964.952.952 0 01-.954-.956" />
            </g>
        </svg>
    );
};

export default Instagram;
